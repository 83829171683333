import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Layout from "./components/layout/Layout";

import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";

import OutletRoot from "./components/outlet/OutletRoot";
import OutletIndex from "./components/outlet/OutletIndex";
import OutletAdd from "./components/outlet/OutletAdd";
import OutletEdit from "./components/outlet/OutletEdit";

import PetugasRoot from "./components/petugas/PetugasRoot";
import PetugasIndex from "./components/petugas/PetugasIndex";
import PetugasAdd from "./components/petugas/PetugasAdd";
import PetugasEdit from "./components/petugas/PetugasEdit";

import PetgakesRoot from "./components/petgakes/PetgakesRoot";
import PetgakesIndex from "./components/petgakes/PetgakesIndex";
import PetgakesAdd from "./components/petgakes/PetgakesAdd";
import PetgakesEdit from "./components/petgakes/PetgakesEdit";

import PetgasusRoot from "./components/petgasus/PetgasusRoot";
import PetgasusIndex from "./components/petgasus/PetgasusIndex";
import PetgasusAdd from "./components/petgasus/PetgasusAdd";
import PetgasusEdit from "./components/petgasus/PetgasusEdit";

import PembayaranRoot from "./components/pembayaran/PembayaranRoot";
import PembayaranIndex from "./components/pembayaran/PembayaranIndex";

import PayBatalRoot from "./components/paybatal/PayBatalRoot";
import PayBatalIndex from "./components/paybatal/PayBatalIndex";

import AbsensiRoot from "./components/absensi/AbsensiRoot";
import AbsensiIndex from "./components/absensi/AbsensiIndex";

import UserRoot from "./components/user/UserRoot";
import UserIndex from "./components/user/UserIndex";

import ErrorPage from "./error-page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "outlet",
        element: <OutletRoot />,
        children: [
          {
            index: true,
            element: <OutletIndex />,
          },
          {
            path: ":id",
            element: <OutletEdit />,
          },
          {
            path: "add",
            element: <OutletAdd />,
          },
        ],
      },
      {
        path: "petugas",
        element: <PetugasRoot />,
        children: [
          {
            index: true,
            element: <PetugasIndex />,
          },
          {
            path: ":id",
            element: <PetugasEdit />,
          },
          {
            path: "add",
            element: <PetugasAdd />,
          },
        ],
      },
      {
        path: "petgakes",
        element: <PetgakesRoot />,
        children: [
          {
            index: true,
            element: <PetgakesIndex />,
          },
          {
            path: ":id",
            element: <PetgakesEdit />,
          },
          {
            path: "add",
            element: <PetgakesAdd />,
          },
        ],
      },
      {
        path: "petgasus",
        element: <PetgasusRoot />,
        children: [
          {
            index: true,
            element: <PetgasusIndex />,
          },
          {
            path: ":id",
            element: <PetgasusEdit />,
          },
          {
            path: "add",
            element: <PetgasusAdd />,
          },
        ],
      },
      {
        path: "pembayaran",
        element: <PembayaranRoot />,
        children: [
          {
            index: true,
            element: <PembayaranIndex />,
          },
        ],
      },
      {
        path: "paybatal",
        element: <PayBatalRoot />,
        children: [
          {
            index: true,
            element: <PayBatalIndex />,
          },
        ],
      },
      {
        path: "absensi",
        element: <AbsensiRoot />,
        children: [
          {
            index: true,
            element: <AbsensiIndex />,
          },
        ],
      },
      {
        path: "user",
        element: <UserRoot />,
        children: [
          {
            index: true,
            element: <UserIndex />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
