import React from "react";

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <p>
          <strong>PSIKOLOGI SIM ONLINE</strong>
          <br />
          copyright&copy;2021 | PT. CAKRA DIGITAL INTELEKTUALITA
          <br />
          Allright Reserved
        </p>
      </div>
    </section>
  );
};

export default Footer;
