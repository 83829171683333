/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { useNavigate, Link } from "react-router-dom";
import { IconContext } from "react-icons";
import {
  FaCamera,
  FaStore,
  FaBook,
  FaUsersCog,
  FaKey,
  FaLayerGroup,
  FaCreditCard,
  FaChartPie,
  FaMoneyBillWave,
  FaChartLine,
  FaRecycle,
  FaFolderOpen,
  FaUserNurse,
  FaUserSecret,
} from "react-icons/fa";
import { API_URL_AUTH } from "../config/config";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Dashboard = () => {
  const [namaUser, setNamaUser] = useState("");
  const [level, setLevel] = useState("");
  const [fotoUser, setFotoUser] = useState("");
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");

  const [balance, setBalance] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
    checkBalance();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
      loadAccount(decoded);
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const checkBalance = async () => {
    await axios
      .post(
        "https://app.jagoan.cloud/1.0/billing/account/rest/getaccount?session=460a0688a6e84c5f9371cb3e4a716d7f78052b3e"
      )
      .then((response) => {
        // console.log(response.data.balance);
        setBalance(response.data.balance);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const loadAccount = (decoded) => {
    setNamaUser(decoded.rows[0].namaUser);
    setLevel(decoded.rows[0].level);
    setFotoUser(decoded.rows[0].fotoUser);
  };

  return (
    <section className="section is-fullwidth mb-4">
      <div className="container">
        <div className="box background-gpsi">
          <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
            <div>
              <div className="media is-flex is-align-items-center">
                <div className="media-left">
                  <figure className="image is-64x64">
                    {fotoUser ? (
                      <img
                        src={fotoUser}
                        className="is-rounded"
                        width="64px"
                        height="64px"
                        alt={`${namaUser}`}
                      />
                    ) : (
                      <Skeleton height="64px" className="is-rounded" />
                    )}
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-5 is-6-mobile has-text-white">
                    {namaUser || <Skeleton width="180px" />}
                  </p>
                  <p className="subtitle text-bold is-7 has-text-white">
                    {level ? <span>{level}</span> : <Skeleton width="120px" />}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p className="has-text-white is-size-6">Saldo Billing Server</p>
              <h1 className="has-text-black text-bold is-size-3">
                {new Intl.NumberFormat("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                }).format(balance)}
              </h1>
            </div>
          </div>
        </div>
        <div className="columns is-desktop">
          <div className="column is-6">
            <h4 className="has-text-white labelTitle has-icons-left">
              <span className="icon is-left">
                <FaKey />
              </span>
              <span>Serivce Authentication</span>
            </h4>
            <p className="mb-3 is-size-7 has-text-white">
              Semua service yang berkaitan dengan service authentication
            </p>
            <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
              <div className="column is-3-desktop is-6-mobile">
                <Link to="outlet" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaStore />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Outlet</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="petugas" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaUsersCog />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Petugas</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="absensi" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaCamera />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Absensi</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="petgakes" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaUserNurse />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Petgakes</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="petgasus" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaUserSecret />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Petgasus</p>
                  </div>
                </Link>
              </div>
            </div>
            <h4 className="has-text-white labelTitle has-icons-left">
              <span className="icon is-left">
                <FaLayerGroup />
              </span>
              <span>Serivce Tes</span>
            </h4>
            <p className="mb-3 is-size-7 has-text-white">
              Semua service yang berkaitan dengan service tes psikologi SIM
            </p>
            <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
              <div className="column is-3-desktop is-6-mobile">
                <Link to="laporanabsen" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaFolderOpen />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Bank Soal</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="karyawan" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaBook />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Kumpulan Soal</p>
                  </div>
                </Link>
              </div>
            </div>
            <h4 className="has-text-white labelTitle has-icons-left">
              <span className="icon is-left">
                <FaCreditCard />
              </span>
              <span>Serivce Pembayaran</span>
            </h4>
            <p className="mb-3 is-size-7 has-text-white">
              Semua service yang berkaitan dengan service pembayaran tes
              psikologi SIM
            </p>
            <div className="columns is-variable is-3-mobile is-1-desktop is-multiline is-desktop is-mobile">
              <div className="column is-3-desktop is-6-mobile">
                <Link to="pembayaran" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaMoneyBillWave />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Pembayaran</p>
                  </div>
                </Link>
              </div>
              <div className="column is-3-desktop is-6-mobile">
                <Link to="paybatal" className="box menu-shortcut">
                  <div className="has-text-centered">
                    <IconContext.Provider
                      value={{
                        className: "icon is-medium has-text-white mb-2",
                      }}
                    >
                      <FaRecycle />
                    </IconContext.Provider>
                    <p className="is-size-7 has-text-white">Data Batal</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="mb-3">
              <div className="labelPage">
                <div className="iconLabel">
                  <IconContext.Provider
                    value={{
                      className: "icon is-large has-text-white",
                    }}
                  >
                    <FaChartPie />
                  </IconContext.Provider>
                </div>
                <div className="vertical-center">
                  <h1 className="title ml-6 is-6 has-text-white">
                    Grafik Absensi Petugas
                  </h1>
                  <p className="subtitle is-7 ml-6 has-text-white">
                    Semua total data absensi petugas
                  </p>
                </div>
              </div>
              <div className="boxCard">
                <p className="has-text-grey">Grafik</p>
              </div>
            </div>
            <div className="mb-3">
              <div className="labelPage">
                <div className="iconLabel">
                  <IconContext.Provider
                    value={{
                      className: "icon is-large has-text-white",
                    }}
                  >
                    <FaChartLine />
                  </IconContext.Provider>
                </div>
                <div className="vertical-center">
                  <h1 className="title ml-6 is-6 has-text-white">
                    Grafik Pembayaran Masuk
                  </h1>
                  <p className="subtitle is-7 ml-6 has-text-white">
                    Semua total data absensi petugas
                  </p>
                </div>
              </div>
              <div className="boxCard">
                <p className="has-text-grey">Grafik</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
